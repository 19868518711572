import React, { useEffect, useRef, useState } from 'react'

import './Header.scss'
import { Link, useLocation } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const menuLeft = [
  {
    title: 'Network',
    link: '/network',
    isBlank: false,
    menus: [
      {
        name: 'Network Status',
        link: 'https://stats.viction.xyz/',
        // link: 'https://www.vicscan.xyz/',
        isBlank: true,
      },
      {
        name: 'Masternodes',
        link: '/masternode',
      },
      {
        name: 'Staking $VIC',
        link: 'https://master.tomochain.com/',
        isBlank: true,
        isHide: true,
      },
      {
        name: 'Block Explorer',
        link: 'https://www.vicscan.xyz/',
        isBlank: true,
      },
      {
        name: 'RPC Provider',
        link: '/',
        isHide: true,
      },
    ],
  },
  {
    title: 'Solutions',
    link: '/',
    isBlank: false,
    menus: [
      // {
      //   name: 'Consumer App',
      //   link: '/consumer-app',
      // },
      // {
      //   name: 'Gaming',
      //   link: '/gaming',
      // },
      // {
      //   name: 'Defi',
      //   link: '/defi',
      // },
      {
        name: 'World Wide Chain',
        link: '/world-wide-chain',
      },
      {
        name: 'Viction DA',
        link: '/viction-da',
      },
    ],
  },
  {
    title: 'Developers',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Documentations',
        link: 'https://docs.viction.xyz/',
        isBlank: true,
      },
      {
        name: 'Tools',
        link: '/',
        isHide: true,
      },
      {
        name: 'Hackathon',
        link: 'http://horizon.viction.xyz/',
        isBlank: true,
      },
      {
        name: 'Github',
        link: '/',
        isHide: true,
      },
    ],
  },
]

const menuRight = [
  {
    title: 'Community',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Community Hub',
        link: '/community-hub',
      },
      {
        name: 'Events',
        link: '/event',
      },
      {
        name: 'Ambassador',
        link: 'https://blog.frontierdao.org/viction-vanguard-ambassador-program-frontierdao-iiw5ijalz1gw',
        isBlank: true,
      },
      {
        name: 'Blog',
        link: 'https://blog.viction.xyz/private/?r=%2F',
        isBlank: true,
      },
    ],
  },
  {
    title: 'Ecosystem',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Ecosystem Landscape',
        link: '/ecosystem',
      },
    ],
  },
]

const menuLeftWwc = [
  {
    title: 'Our Vision',
    link: '/our-vision',
    isBlank: false,
    menus: [
      {
        name: 'Vision',
        link: '/world-wide-chain',
      },
    ],
  },
  {
    title: 'Products and Solutions',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Data Availability',
        link: '/viction-da',
      },
      {
        name: 'Chain Development Kit',
        link: '/gaming',
        isHide: true,
      },
      {
        name: 'Game | Social | DeFi | NFT | Payment',
        link: '/defi',
        isHide: true,
      },
    ],
  },
]

const menuRightWwc = [
  {
    title: 'Developers',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Documentations',
        link: 'https://docs.viction.xyz/',
        isBlank: true,
      },
      {
        name: 'Community',
        link: 'https://docs.viction.xyz/',
        isBlank: true,
      },
      {
        name: 'FAQ',
        link: 'https://docs.viction.xyz/',
        isBlank: true,
      },
    ],
  },
  {
    title: 'Ecosystem',
    link: '/',
    isBlank: false,
    menus: [
      {
        name: 'Ecosystem Landscape',
        link: '/ecosystem',
      },
    ],
  },
]

function Header() {
  const { positionY, widthResize } = useResizeWidth()
  const { pathname } = useLocation()
  const submenuRef = useRef(null)

  const [isShowSub, setIsShowSub] = useState(false)
  const [isShowMenu, setIsShowMenu] = useState(false)

  const isChangeMenu =
    pathname === '/world-wide-chain' || pathname === '/viction-da'

  const dataMenuLeft = isChangeMenu ? menuLeftWwc : menuLeft
  const dataMenuRight = isChangeMenu ? menuRightWwc : menuRight

  const isMobile = widthResize <= 600
  const isHome =
    pathname === '/' ||
    pathname === '/event' ||
    pathname === '/wallet' ||
    pathname === '/viction-da'
  const isChangeBackground = positionY >= 150

  const isChangeColor = isHome && !isChangeBackground ? true : false

  const handleShowSub = () => {
    setIsShowSub(!isShowSub)
  }

  const handleClickLogo = () => {
    setIsShowSub(false)
  }

  const onClickOut = (e) => {
    if (submenuRef.current && !submenuRef.current.contains(e.target)) {
      setIsShowSub(false)
    }
  }

  const onShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  const handleChooseMenu = () => {
    setIsShowMenu(false)
  }

  useEffect(() => {
    window.addEventListener('mousedown', onClickOut)

    return () => window.removeEventListener('mousedown', onClickOut)
  }, [submenuRef, isShowSub])

  useEffect(() => {
    widthResize > 1280 && setIsShowMenu(false)
    widthResize <= 1280 && setIsShowSub(false)
  }, [widthResize])

  useEffect(() => {
    if (isShowMenu) {
      document.querySelector('html').style.overflowY = 'hidden'
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('html').style.touchAction = 'none'
      document.querySelector('body').style.touchAction = 'none'
    } else {
      document.querySelector('html').style.overflowY = !isMobile
        ? 'unset'
        : 'auto'
      document.querySelector('body').style.overflowY = !isMobile
        ? 'unset'
        : 'auto'
      document.querySelector('html').style.touchAction = 'unset'
      document.querySelector('body').style.touchAction = 'unset'
    }
  }, [isShowMenu])

  return (
    <div
      className={`header-wrapper ${
        isChangeBackground && 'header-wrapper--change-background'
      } ${isShowSub && 'header-wrapper--sub-open'}`}>
      <div className='header-container'>
        <div className='header-mobile'>
          <Link to='/' onClick={handleChooseMenu}>
            <span
              className={`icon-web_logo icon-logo-mobile ${
                isShowMenu && 'icon-logo-mobile--menu-home'
              } ${isChangeColor && 'icon-logo-mobile--text-change'}`}></span>
          </Link>

          <span
            className={`icon-${
              isShowMenu ? 'web_close' : 'web_menu'
            } icon-menu ${isShowMenu && 'icon-menu--menu-home'} ${
              isChangeColor && 'icon-menu--text-change'
            }`}
            onClick={onShowMenu}></span>
        </div>

        <div className='header-menu-wrapper'>
          <div className='header-menus'>
            {dataMenuLeft.map((item, i) => {
              return (
                <div
                  className={`menu-item ${
                    isShowSub && 'menu-item--menu-home'
                  } ${isChangeColor && 'menu-item--text-change'}`}
                  key={i}>
                  <div className='text-menu text-hover' onClick={handleShowSub}>
                    {item.title}
                  </div>
                </div>
              )
            })}
          </div>

          <Link to='/' onClick={handleClickLogo}>
            <span
              className={`icon-web_logo icon-logo ${
                isShowSub && 'icon-logo--menu-home'
              } ${isChangeColor && 'icon-logo--text-change'}`}></span>
          </Link>

          <div className='header-menus header-menus--right'>
            {dataMenuRight.map((item, i) => {
              return (
                <div
                  className={`menu-item menu-item--right ${
                    isShowSub && 'menu-item--menu-home'
                  } ${isChangeColor && 'menu-item--text-change'}`}
                  key={i}>
                  <div className='text-menu text-hover' onClick={handleShowSub}>
                    {item.title}
                  </div>
                </div>
              )
            })}
          </div>

          {isShowSub && (
            <span
              className='icon-web_close icon-close'
              onClick={handleClickLogo}></span>
          )}
        </div>

        <div
          className={`header-submenu ${isShowSub && 'header-submenu--show'}`}
          ref={submenuRef}>
          <div className='submenu-item'>
            {dataMenuLeft.map((item, i) => {
              return (
                <div className='menu-sub menu-sub--right' key={i}>
                  {!item.isHide &&
                    item.menus.map((itemSub, index) => {
                      return (
                        <div
                          className={`item-sub ${
                            itemSub.isComing && 'item-sub--coming'
                          } ${itemSub.isHide && 'item-sub--hide'}`}
                          key={index}
                          onClick={handleShowSub}>
                          <Link
                            to={itemSub.link}
                            target={itemSub.isBlank && '_blank'}
                            className='text-hover'>
                            {itemSub.name}
                          </Link>
                        </div>
                      )
                    })}
                </div>
              )
            })}
          </div>

          <div className='submenu-item submenu-item--right'>
            {dataMenuRight.map((item, i) => {
              return (
                <div className='menu-sub' key={i}>
                  {item.menus.map((itemSub, index) => {
                    return (
                      <div
                        className={`item-sub ${
                          itemSub.isComing && 'item-sub--coming'
                        } ${itemSub.isHide && 'item-sub--hide'}`}
                        key={index}
                        onClick={handleShowSub}>
                        <Link
                          to={itemSub.link}
                          target={itemSub.isBlank && '_blank'}
                          className='text-hover'>
                          {itemSub.name}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div
        className={`header-mobile-wrapper ${
          isShowMenu && 'header-mobile-wrapper--show'
        }`}>
        <div className='header-tablet-container'>
          <div className={`menu-list ${isMobile && 'menu-list--mobile'}`}>
            {dataMenuLeft.map((item, i) => {
              return (
                <div
                  className={`item-menus ${item.isHide && 'item-menus--hide'} ${
                    isMobile && 'item-menus--mobile'
                  }`}
                  key={i}>
                  <div className='item-menus__title'>{item.title}</div>

                  <div className='item-menus__list'>
                    {item.menus.map((itemMenu, index) => {
                      return (
                        <Link
                          to={itemMenu.link}
                          onClick={handleChooseMenu}
                          className={`menu-text ${
                            itemMenu.isHide && 'menu-text--hide'
                          }`}
                          key={index}>
                          {itemMenu.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <div className={`menu-list ${isMobile && 'menu-list--mobile'}`}>
            {dataMenuRight.map((item, i) => {
              return (
                <div
                  className={`item-menus ${item.isHide && 'item-menus--hide'} ${
                    isMobile && 'item-menus--mobile'
                  }`}
                  key={i}>
                  <div className='item-menus__title'>{item.title}</div>

                  <div className='item-menus__list'>
                    {item.menus.map((itemMenu, index) => {
                      return (
                        <Link
                          to={itemMenu.link}
                          onClick={handleChooseMenu}
                          className={`menu-text ${
                            itemMenu.isHide && 'menu-text--hide'
                          }`}
                          key={index}>
                          {itemMenu.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
